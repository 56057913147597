import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'address', ...(require('/var/jenkins_home/workspace/iems@2/src/models/address.js').default) });
app.model({ namespace: 'dictionary', ...(require('/var/jenkins_home/workspace/iems@2/src/models/alarm/dictionary.js').default) });
app.model({ namespace: 'app', ...(require('/var/jenkins_home/workspace/iems@2/src/models/app.js').default) });
app.model({ namespace: 'audio', ...(require('/var/jenkins_home/workspace/iems@2/src/models/audio.js').default) });
app.model({ namespace: 'base', ...(require('/var/jenkins_home/workspace/iems@2/src/models/base.js').default) });
app.model({ namespace: 'dict', ...(require('/var/jenkins_home/workspace/iems@2/src/models/dict.js').default) });
app.model({ namespace: 'energyStation', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyStation.js').default) });
app.model({ namespace: 'Adjust', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Charge/Adjust.js').default) });
app.model({ namespace: 'Base', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Charge/Base.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Charge/index.js').default) });
app.model({ namespace: 'Overview', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Charge/Overview.js').default) });
app.model({ namespace: 'Quantity', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Charge/Quantity.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Comparison/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/Contrast/index.js').default) });
app.model({ namespace: 'energyDevice', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/DeviceManagement/energyDevice.js').default) });
app.model({ namespace: 'energyDeviceList', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/DeviceManagement/energyDeviceList.js').default) });
app.model({ namespace: 'transformer', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/DeviceManagement/transformer.js').default) });
app.model({ namespace: 'transformerList', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/DeviceManagement/transformerList.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/LateralStrain/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/OutputValue/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/ranking/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/energyWeb/SwitchableTree/index.js').default) });
app.model({ namespace: 'exceptionSocket', ...(require('/var/jenkins_home/workspace/iems@2/src/models/exceptionSocket.js').default) });
app.model({ namespace: 'global', ...(require('/var/jenkins_home/workspace/iems@2/src/models/global.js').default) });
app.model({ namespace: 'metricCode', ...(require('/var/jenkins_home/workspace/iems@2/src/models/metricCode.js').default) });
app.model({ namespace: 'right', ...(require('/var/jenkins_home/workspace/iems@2/src/models/right.js').default) });
app.model({ namespace: 'sellElectric', ...(require('/var/jenkins_home/workspace/iems@2/src/models/sellElectric.js').default) });
app.model({ namespace: 'station', ...(require('/var/jenkins_home/workspace/iems@2/src/models/station.js').default) });
app.model({ namespace: 'statistic', ...(require('/var/jenkins_home/workspace/iems@2/src/models/statistic.js').default) });
app.model({ namespace: 'audio', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/audio.js').default) });
app.model({ namespace: 'dictionary', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/dictionary.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/history/index.js').default) });
app.model({ namespace: 'popup', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/popup.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/settings/index.js').default) });
app.model({ namespace: 'stat', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/stat.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/unhandle/index.js').default) });
app.model({ namespace: 'unhandleAside', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarm/unhandleAside.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/alarmNew/index.js').default) });
app.model({ namespace: 'station', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/common/station.js').default) });
app.model({ namespace: 'user', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/common/user.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/elecScheme/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/informationAnalyze/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/priceScheme/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/priceSchemeHistory/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/ReportCenter/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/stationMonitor/index.js').default) });
app.model({ namespace: 'stations', ...(require('/var/jenkins_home/workspace/iems@2/src/models/storage/stations.js').default) });
app.model({ namespace: 'style', ...(require('/var/jenkins_home/workspace/iems@2/src/models/style.js').default) });
app.model({ namespace: 'tableData', ...(require('/var/jenkins_home/workspace/iems@2/src/models/tableData.js').default) });
app.model({ namespace: 'upload', ...(require('/var/jenkins_home/workspace/iems@2/src/models/upload.js').default) });
app.model({ namespace: 'user', ...(require('/var/jenkins_home/workspace/iems@2/src/models/user.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
