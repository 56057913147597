import * as service from '@/services/storage/station';
import moment from 'moment';
import { enddateFormat, startdateFormat } from '../components/lib/iem-TimeRange';
import { numFormat, formatElectricity, formatInstalledPower } from '@/utils/toolFunction';
import { convertDecimal } from '@/utils/tool';
import { defaultStaticInfo } from '../pages/z-energyPower/generateAnalyse/components/info';

export default {
  namespace: 'statistic',
  state: {
    queryData: {
      stationIds: [],
      startTime: '',
      endTime: '',
      type: undefined // type: 1代表日，2代表月，3代表年
    },
    sortParam: {
      //表格排序字段参数
      field: '',
      sort: ''
    },
    statisticData: undefined,
    tableData: [],
    exportLoading: false,
    tableLoading: false,
    // 发电分析数据
    analyseData: {
      photovoltaicAnalyzeStationVOList: [],
      staticInfo: defaultStaticInfo
    },
    chargeDurationData: [],
    chargeTimesData: []
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    }
  },
  effects: {
    // 获取表格数据
    *fetchTableData ({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'update',
          payload: { tableLoading: true }
        });
        const queryData = yield select(state => state.statistic.queryData);
        const sortParam = yield select(state => state.statistic.sortParam);
        const data = yield call(service.queryStationTableList, { ...payload, ...queryData, ...sortParam });
        yield put({
          type: 'update',
          payload: { tableData: data || [], tableLoading: false }
        });
      } catch (err) {
        console.error('fetchStationList err: ', err);
      }
    },

    // 获取统计数据
    *fetchStatisticData ({ payload }, { call, put, select }) {
      try {
        const queryData = yield select(state => state.statistic.queryData);
        const data = yield call(service.queryStationStatistics, { ...payload, ...queryData });
        yield put({
          type: 'update',
          payload: { statisticData: data }
        });
      } catch (err) {
        console.error('fetchStationList err: ', err);
      }
    },
    // 获取充电时长
    *fetchChargeDurationData ({ payload }, { call, put, select }) {
      try {
        const queryData = yield select(state => state.statistic.queryData);
        const data = yield call(service.queryStationStatistics, { ...payload, ...queryData });
        yield put({
          type: 'update',
          payload: { chargeDurationData: data }
        });
      } catch (err) {
        console.error('fetchStationList err: ', err);
      }
    },
    // 获取充电次数
    *fetchChargeTimesData ({ payload }, { call, put, select }) {
      try {
        const queryData = yield select(state => state.statistic.queryData);
        const data = yield call(service.queryStationStatistics, { ...payload, ...queryData });
        yield put({
          type: 'update',
          payload: { chargeTimesData: data }
        });
      } catch (err) {
        console.error('fetchStationList err: ', err);
      }
    },

    *getSingChartData ({ payload: { callBack, ...otherPayload } = {} }, { call, put, select }) {
      try {
        const data = yield call(service.queryStationChartList, { ...otherPayload });
        callBack(data);
      } catch (err) {
        console.error('fetchStationList err: ', err);
      }
    },

    // 获取全部数据
    *fetchData ({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'update',
          payload: { tableLoading: true }
        });
        const queryCommonParams = yield select(state => state.statistic.queryData);
        const querySortParmas = yield select(state => state.statistic.sortParam);

        // 合并请求参数
        let queryParmas = {
          ...queryCommonParams,
          ...querySortParmas,
          type: queryCommonParams.type === 3 ? 2 : 1, //"type" 1月日，2年
          startTime: moment(queryCommonParams.startTime).format(startdateFormat),
          endTime: moment(queryCommonParams.endTime).format(enddateFormat)
        };

        const data = yield call(service.queryStationAllData, { ...payload, ...queryParmas });
        if (data) {
          const {
            photovoltaicAnalyzeStationVOList,
            totalPutElectric,
            totalPutElectricHour,
            totalReverseActiveElectricity,
            totalPrivateElectirc,
            totalNetElectricity,
            totalPvInstalledCapacity
          } = data;
          yield put({
            type: 'update',
            payload: {
              analyseData: {
                photovoltaicAnalyzeStationVOList,
                staticInfo: [
                  {
                    img: 'icon-shandian',
                    label: '发电量',
                    value: formatElectricity(totalPutElectric, 'm').value,
                    unit: formatElectricity(totalPutElectric, 'm').unit,
                    color: '#0094AD'
                  },
                  {
                    img: 'icon-a-24xiaoshiyongdianliangbili',
                    label: '等效发电小时数',
                    value: convertDecimal(totalPutElectricHour),
                    unit: 'h',
                    color: '#577980'
                  },
                  {
                    img: 'icon-fushe',
                    label: '电表电量',
                    value: formatElectricity(totalReverseActiveElectricity, 'm').value,
                    unit: formatElectricity(totalReverseActiveElectricity, 'm').unit,
                    color: '#FA8220'
                  },
                  {
                    img: 'icon-dianbiao',
                    label: '自用电量',
                    value: formatElectricity(totalPrivateElectirc, 'm').value,
                    unit: formatElectricity(totalPrivateElectirc, 'm').unit,
                    color: '#886CFF'
                  },
                  {
                    img: 'icon-shangwangdianliang',
                    label: '上网电量',
                    value: formatElectricity(totalNetElectricity, 'm').value,
                    unit: formatElectricity(totalNetElectricity, 'm').unit,
                    color: '#588AF4'
                  },
                  {
                    img: 'icon-chunengxinxi',
                    label: '装机容量',
                    value: formatInstalledPower(totalPvInstalledCapacity, 'm').value,
                    unit: formatInstalledPower(totalPvInstalledCapacity, 'm').unit,
                    color: '#588AF4'
                  }
                ]
              },
              tableLoading: false
            }
          });
        } else {
          yield put({
            type: 'update',
            payload: {
              analyseData: {
                photovoltaicAnalyzeStationVOList: [],
                staticInfo: defaultStaticInfo
              },
              tableLoading: false
            }
          });
        }
      } catch (err) {
        console.error('fetchData err: ', err);
      }
    },

    // 导出
    *exportData ({ payload }, { call, put, select }) {
      const { type, ...otherPayload } = payload;
      yield put({
        type: 'update',
        payload: { exportLoading: true }
      });
      try {
        const queryCommonParams = yield select(state => state.statistic.queryData);
        const querySortParmas = yield select(state => state.statistic.sortParam);
        // 合并请求参数,发电分析特殊处理
        let queryParmas =
          type === 'analyse'
            ? {
              ...queryCommonParams,
              ...querySortParmas,
              type: queryCommonParams.type === 3 ? 2 : 1, //"type" 1月日，2年
              startTime: moment(queryCommonParams.startTime).format(startdateFormat),
              endTime: moment(queryCommonParams.endTime).format(enddateFormat)
            }
            : {
              ...queryCommonParams,
              ...querySortParmas
            };
        const data = yield call(service.exportDataByquery, { ...otherPayload, ...queryParmas });
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = `${data}`;
        link.setAttribute('download', `导出列表.xlsx`); // 第二个参数是自定义的名字,根据自己需要, 特别注意一下，苹果本上这个下载下来是没有后缀名的，但是可以打开，如果需要就带着后缀名.xsl。
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('fetchData err: ', err);
      } finally {
        yield put({
          type: 'update',
          payload: { exportLoading: false }
        });
      }
    }
  }
};
