import * as ossService from '@/services/oss';


export default {
  namespace: 'upload',

  state: {
    data: {
      OSS_URL: localStorage.getItem('OSS_URL')
    },
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *multipartUpload({ payload: { cb, storeAs, file, opt } }, { call }) {
      const result = yield call(ossService.multipartUpload, storeAs, file, opt);
      yield call(cb, result);
    },
    *singUpload({ payload: { cb, file } }, { call }) {
      const result = yield call(ossService.singUpload, file, cb);
      yield call(cb, result?.data);
    },
    *getOssUrl({ }, { call }) {
      const result = yield call(ossService.getOssUrl);
      localStorage.setItem('OSS_URL', result?.data)
      yield put({ type: 'update', payload: { OSS_URL: result?.data } });
    },
  },

  subscriptions: {
  },
};