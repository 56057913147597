import dictionary from '@/utils/dictionary';
import { convertDecimal } from './tool';
import { GROUP_ROUTE_DETAIL_PATHS, GROUP_ROUTE_PATHS } from '../constant';

import storage from '@/utils/storage-zdy';

const { SCHEMES } = dictionary;

export function dataProcessing (obj = {}) {
  const map = [];
  const sharpPeriod = [...(obj.sharpPeriod || '').split(',')];
  const peakPeriod = [...(obj.peakPeriod || '').split(',')];
  const flatPeriod = [...(obj.flatPeriod || '').split(',')];
  const valleyPeriod = [...(obj.valleyPeriod || '').split(',')];
  sharpPeriod.forEach(item => {
    // 尖
    const o = {};
    o.type = 1;
    o.time = item && item.split('-');
    o.price = obj.sharpPrice;
    o.dischargePrice = obj.sharpOffPrice;
    // if (o.price) {
    map.push(o);
    // }
  });
  peakPeriod.forEach(item => {
    // 峰
    const o = {};
    o.type = 2;
    o.time = item && item.split('-');
    o.price = obj.peakPrice;
    o.dischargePrice = obj.peakOffPrice;
    // if (o.price) {
    map.push(o);
    // };
  });
  flatPeriod.forEach(item => {
    // 平
    const o = {};
    o.type = 3;
    o.time = item && item.split('-');
    o.price = obj.flatPrice;
    o.dischargePrice = obj.flatOffPrice;
    // if (o.price) {
    map.push(o);
    // };
  });
  valleyPeriod.forEach(item => {
    // 谷
    const o = {};
    o.type = 4;
    o.time = item && item.split('-');
    o.price = obj.valleyPrice;
    o.dischargePrice = obj.valleyOffPrice;
    // if (o.price) {
    map.push(o);
    // };
  });
  const sortMap = map.sort((a, b) => {
    return parseInt(a.time[0]) - parseInt(b.time[0]);
  });
  sortMap.forEach((item, index) => {
    const len = index + 1;
    item.num = len < 10 ? `0${len}` : `${len}`;
  });

  return sortMap;
}

export function getChartOption (PriceList = []) {
  const sortedList = PriceList.filter(i => i.time).sort((a, b) => (a.time[0] > b.time[0] ? 1 : -1));
  const price = new Array(4).toString().split(',');
  const TYPE = ['', '尖', '峰', '平', '谷'];
  const chartData = [];
  const isHasPush = [];
  sortedList.forEach(element => {
    const num = moment(element.time[1], 'HH:mm').diff(moment(element.time[0], 'HH:mm'), 'hours');
    const arr = String(element.price || '.').split('.');
    const value = `${TYPE[element.type]} ${arr[0] || 0}.${(arr[1] || '').length === 5 ? arr[1] : addZero(arr[1])}`;
    const dataArr = getPriceByNum(num, value);
    chartData.push(...dataArr);
    if (isHasPush.indexOf(element.type) === -1) {
      isHasPush.push(element.type);
      price[element.type - 1] = element.price;
    }
  });
  const result = {
    price: price.reverse(),
    chartData
  };
  return result;
}

export function filteryAxis (price = []) {
  const TYPE = ['尖', '峰', '平', '谷'].reverse();
  const resule = [0];
  price.forEach((price, index) => {
    if (Number(price)) {
      const arr = String(price || '.').split('.');
      const value = `${TYPE[index]} ${arr[0] || 0}.${(arr[1] || '').length === 5 ? arr[1] : addZero(arr[1])}`;
      resule.push(value);
    }
  });
  return resule;
}

export function numFormat (param) {
  let value = '';
  try {
    const num = param + '';
    const numGroup = num.includes('.') ? num.split('.') : [num];
    // 千位分隔符格式的规则是数字的整数部分每三位一组，以“，”分节。小数部分不分节
    const intFn = numStr =>
      numStr
        .split('')
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ',') + prev;
        });
    const intNum = intFn(numGroup[0]);
    const floatNum = numGroup[1] ? '.' + numGroup[1] : '';
    value = intNum + floatNum;
  } catch (error) {
    value = param;
  }
  return value;
}

function addZero (num) {
  const len = 5 - String(num || '').length;
  let zero = num || '';
  for (let i = 0; i < len; i++) {
    zero += '0';
  }
  return zero;
}

// 是否是数值
export function isNumeric (str) {
  return !isNaN(parseFloat(str)) && isFinite(str);
}

export function transformToK (value) {
  return convertDecimal(value)
}

export function transformToM (value) {
  return convertDecimal(value / 1000)
}

export function transformToG (value) {
  return convertDecimal(value / (1000 * 1000))
}

// 转化电量
export function formatElectricity (value, scale) {
  let isK = scale === 'k'
  let isM = scale === 'm'
  let isG = scale === 'g'
  let isNumer = isNumeric(value);
  if (!isNumer) {
    return {
      value,
      unit: isM ? 'MWh' : 'kWh'
    };
  } else {
    value = Number(value);
  }

  if (isK) {
    return {
      value: transformToK(value),
      unit: 'kWh'
    }
  }

  if (isM) {
    return {
      value: transformToM(value),
      unit: 'MWh'
    }
  }

  if (isG) {
    return {
      value: transformToG(value),
      unit: 'GWh'
    }
  }

  let params = {};
  if (value >= 10000000) {
    params = {
      value: convertDecimal(value / 1000000),
      unit: 'GWh'
    };
  } else if (value >= 10000) {
    params = {
      value: convertDecimal(value / 1000),
      unit: 'MWh'
    };
  } else {
    params = {
      value: convertDecimal(value),
      unit: 'kWh'
    };
  }
  return params;
}


// 转换功率
export function formatCapacity (value, scale) {
  let isM = scale === 'm'
  let isNumer = isNumeric(value);
  if (!isNumer) {
    return {
      value,
      unit: isM ? 'MW' : 'kW'
    };
  } else {
    value = Number(value);
  }
  if (isM) {
    return {
      value: transformToM(value),
      unit: 'MW'
    }
  }
  let params = {};
  if (value >= 10000000) {
    params = {
      value: convertDecimal(value / 1000000),
      unit: 'GW'
    };
  } else if (value >= 10000) {
    params = {
      value: convertDecimal(value / 1000),
      unit: 'MW'
    };
  } else {
    params = {
      value: convertDecimal(value),
      unit: 'kW'
    };
  }
  return params;
}

// 转换容量
export function formatVolume (value, scale) {
  let isM = scale === 'm'
  let isNumer = isNumeric(value);
  if (!isNumer) {
    return {
      value,
      unit: isM ? 'MVA' : 'kVA'
    };
  } else {
    value = Number(value);
  }
  if (isM) {
    return {
      value: transformToM(value),
      unit: 'MVA'
    }
  }
  let params = {};
  if (value >= 10000000) {
    params = {
      value: convertDecimal(value / 1000000),
      unit: 'GVA'
    };
  } else if (value >= 10000) {
    params = {
      value: convertDecimal(value / 1000),
      unit: 'MVA'
    };
  } else {
    params = {
      value: convertDecimal(value),
      unit: 'kVA'
    };
  }
  return params;
}

// 装机功率  kWp   MWp
export function formatInstalledPower (value, scale) {
  let isM = scale === 'm'
  let isNumer = isNumeric(value);
  if (!isNumer) {
    return {
      value: value || '--',
      unit: isM ? 'MWp' : 'kWp'
    };
  } else {
    value = Number(value);
  }

  if (isM) {
    return {
      value: transformToM(value),
      unit: 'MWp'
    }
  }

  let params = {};
  if (value >= 10000) {
    params = {
      value: convertDecimal(value / 1000),
      unit: 'MWp'
    };
  } else {
    params = {
      value: convertDecimal(value),
      unit: 'kWp'
    };
  }
  return params;
}

/*
obj.endTime = item[`time-end-${num}`];
      obj.startTime = item[`time-start-${num}`];
      obj.lowerSoc = item[`soc-start-${num}`];
      obj.upperSoc = item[`soc-end-${num}`];
      obj.power = item[`power-${num}`];
*/

export function getPowerData (segments, capacity) {
  // const powerArr = [];

  const powerArr = segments.reduce(
    (total, current) => {
      const num = moment(current.endTime, 'HH:mm').diff(moment(current.startTime, 'HH:mm'), 'hours');
      const endValue = total[total.length - 1];

      // const power = window.parseInt(current.upperSoc) - window.parseInt(current.lowerSoc);
      const power = current.power
      const differenceValue = (power / num).toFixed(2);
      const dataArr = getDataByNumValue(endValue, num, power);
      // console.log(endValue, num, differenceValue); 
      total.push(...dataArr);
      return total;
    },
    [segments[0]?.lowerSoc]
  );
  return powerArr;
}

const getDataByNumValue = (endValue, num, differenceValue) => {
  const data = [];
  data.push(endValue);
  for (let i = 1; i <= num; i++) {
    const lastValue = data[data.length - 1];
    // const val = Number(lastValue) + Number(differenceValue);
    // if (i === num) {
    //   data.push(Math.ceil(val));
    // } else {
    //   data.push(val);
    // }
    data.push(differenceValue)

  }
  return data.slice(1);
};

export function restMounth (hasSelect = []) {
  const total = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const arr = [];
  total.forEach(item => {
    if (hasSelect.indexOf(item) === -1) {
      arr.push(item);
    }
  });
  return arr;
}

export function getGroupByitem (obj = {}) {
  const formSeaon = {};
  const formUnSeaon = {};
  Object.keys(obj).forEach(item => {
    if (item.indexOf('unseason') === -1) {
      formSeaon[item] = obj[item];
    } else {
      const key = item.split('unseason-')[1];
      formUnSeaon[key] = obj[item];
    }
  });
  return {
    formSeaon,
    formUnSeaon
  };
}

export function restoreForm (form = {}, length) {
  const type = [];
  const formArr = [];
  const result = [];
  const keysArray = Object.keys(form);
  keysArray.forEach(item => {
    if (item.indexOf('type') !== -1) {
      type.push(form[item]);
    }
  });
  for (let i = 1; i <= length; i++) {
    const obj = {};
    const num = i < 10 ? `0${i}` : `${i}`;
    obj.num = num;
    keysArray.forEach(item => {
      const index = item.indexOf(num);
      if (index !== -1) {
        obj[item] = form[item];
      }
    });
    formArr.push(obj);
  }
  formArr.map(item => {
    const obj = {
      num: item.num,
      type: item[`type-${item.num}`],
      price: item[`price-${item.num}`],
      time: [item[`time-start-${item.num}`], item[`time-end-${item.num}`]]
    };
    result.push(obj);
    return '';
  });
  return result;
}

export function fallbackFormData (formData = {}) {
  const keysArray = Object.keys(formData);
  const list = keysArray.reduce(
    (total, current, index) => {
      if (current.indexOf('01') === 0) {
        // 尖
        const arrIndex = Number(current.split('-')[1]);
        total[0].price = formData['01-price'];
        total[0].sharpOffPrice = formData['01-dischargePrice'];
        if (typeof total[0].time[arrIndex] === 'undefined' && arrIndex >= 0) {
          total[0].time[arrIndex] = [];
          total[0].time[arrIndex][0] = formData[`01-${arrIndex}-time-start`];
          total[0].time[arrIndex][1] = formData[`01-${arrIndex}-time-end`];
        }
      }
      if (current.indexOf('02') === 0) {
        // 峰
        const arrIndex = Number(current.split('-')[1]);
        total[1].price = formData['02-price'];
        total[1].peakOffPrice = formData['02-dischargePrice'];
        if (typeof total[1].time[arrIndex] === 'undefined' && arrIndex >= 0) {
          total[1].time[arrIndex] = [];
          total[1].time[arrIndex][0] = formData[`02-${arrIndex}-time-start`];
          total[1].time[arrIndex][1] = formData[`02-${arrIndex}-time-end`];
        }
      }
      if (current.indexOf('03') === 0) {
        // 平
        const arrIndex = Number(current.split('-')[1]);
        total[2].price = formData['03-price'];
        total[2].flatOffPrice = formData['03-dischargePrice'];
        if (typeof total[2].time[arrIndex] === 'undefined' && arrIndex >= 0) {
          total[2].time[arrIndex] = [];
          total[2].time[arrIndex][0] = formData[`03-${arrIndex}-time-start`];
          total[2].time[arrIndex][1] = formData[`03-${arrIndex}-time-end`];
        }
      }
      if (current.indexOf('04') === 0) {
        // 谷
        const arrIndex = Number(current.split('-')[1]);
        total[3].price = formData['04-price'];
        total[3].valleyOffPrice = formData['04-dischargePrice'];
        if (typeof total[3].time[arrIndex] === 'undefined' && arrIndex >= 0) {
          total[3].time[arrIndex] = [];
          total[3].time[arrIndex][0] = formData[`04-${arrIndex}-time-start`];
          total[3].time[arrIndex][1] = formData[`04-${arrIndex}-time-end`];
        }
      }
      return total;
    },
    [
      {
        type: 1,
        time: [],
        num: '01'
      },
      {
        type: 2,
        time: [],
        num: '02'
      },
      {
        type: 3,
        time: [],
        num: '03'
      },
      {
        type: 4,
        time: [],
        num: '04'
      }
    ]
  );
  return list;
}

export function checkForm (form = {}, length) {
  const type = [];
  const formArr = []; // form表格数据
  let hasSameTime = '';
  const keysArray = Object.keys(form);

  keysArray.forEach(item => {
    if (item.indexOf('type') !== -1) {
      type.push(form[item]);
    }
  });

  for (let i = 1; i <= length; i++) {
    const obj = {};
    const num = i < 10 ? `0${i}` : `${i}`;
    obj.num = num;
    keysArray.forEach(item => {
      const index = item.indexOf(num);
      if (index !== -1) {
        obj[item] = form[item];
      }
    });
    formArr.push(obj);
  }

  const totalTime = formArr.reduce((total, current) => {
    // 所有时间;
    const timeEnd = current[`time-end-${current.num}`];
    const timeStart = current[`time-start-${current.num}`];
    if (timeEnd === timeStart && (timeEnd !== '00:00' && timeStart !== '00:00')) {
      hasSameTime = '起止时间不能相同';
    }
    if (moment(timeEnd, 'HH:mm').diff(moment(timeStart, 'HH:mm'), 'hours') < 0) {
      hasSameTime = '结束时间不能小于开始时间';
    }
    const hours = moment(timeEnd, 'HH:mm').diff(moment(timeStart, 'HH:mm'), 'hours');
    return total + hours;
  }, 0);
  const typeTip = tips(type); // 是否覆盖所有峰断


  const result = {
    resuleformArr: formArr.filter(item => {
      const timeEnd = item[`time-end-${item.num}`];
      const timeStart = item[`time-start-${item.num}`];
      if (timeEnd === '00:00' && timeStart === '00:00') return false;
      return true;
    }),
    typeTips: typeTip.replace(/\、$/g, ''),
    totalTime,
    hasSameTime
  };
  return result;
}

export function callBackForm (formSeaonList = []) {
  let hasSameTime = '';
  const totalTime = formSeaonList.reduce((totalTime, current) => {
    // 所有时间;
    const timeArr = current.time;

    timeArr.forEach(timeItem => {
      const timeEnd = timeItem[1];
      const timeStart = timeItem[0];
      const hours = moment(timeEnd, 'HH:mm').diff(moment(timeStart, 'HH:mm'), 'hours');
      if (timeEnd === timeStart && (timeEnd !== '00:00' && timeStart !== '00:00')) {
        hasSameTime = '起止时间不能相同';
      }
      if (moment(timeEnd, 'HH:mm').diff(moment(timeStart, 'HH:mm'), 'hours') < 0) {
        hasSameTime = '结束时间不能小于开始时间';
      }
      totalTime += hours;
    });

    return totalTime;
  }, 0);
  const result = {
    totalTime,
    hasSameTime
  };
  return result;
}

export function formatDetails (cycleBill, resultSeason, resultUnSeason, seasonPriceMounths, unseasonPriceMounths) {

  // 1 季节电价，0非季节电价
  const seasonMounths = String(cycleBill) === '1' ? seasonPriceMounths : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const details = [];

  const resultSeasonObj = formartArrayTomap(seasonMounths, resultSeason);
  const resultUnSeasonObj = formartArrayTomap(unseasonPriceMounths, resultUnSeason);
  details.push(resultSeasonObj);
  if (String(cycleBill) === '1') {
    details.push(resultUnSeasonObj);
  }
  return details;
}

export function formatDetailsObj (cycleBill, resultSeason, resultUnSeason, seasonPriceMounths, unseasonPriceMounths) {
  const seasonMounths = String(cycleBill) === '1' ? seasonPriceMounths : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const details = [];

  const resultSeasonObj = formartArrayTomapObj(seasonMounths, resultSeason);
  const resultUnSeasonObj = formartArrayTomapObj(unseasonPriceMounths, resultUnSeason);
  details.push(resultSeasonObj);
  if (String(cycleBill) === '1') {
    details.push(resultUnSeasonObj);
  }
  return details;
}

function formartArrayTomapObj (month, arr) {
  return arr.reduce(
    (total, current) => {
      if (current.type === 1) {
        total.sharpPrice = current.price;
        total.sharpOffPrice = current.sharpOffPrice;
        total.sharpPeriod = toStr(current.time);
      }

      if (current.type === 2) {
        total.peakPrice = current.price;
        total.peakOffPrice = current.peakOffPrice;
        total.peakPeriod = toStr(current.time);
      }

      if (current.type === 3) {
        total.flatPrice = current.price;
        total.flatOffPrice = current.flatOffPrice;
        total.flatPeriod = toStr(current.time);
      }

      if (current.type === 4) {
        total.valleyPrice = current.price;
        total.valleyOffPrice = current.valleyOffPrice;
        total.valleyPeriod = toStr(current.time);
      }
      return total;
    },
    {
      months: month.join(','),
      sharpPeriod: '',
      sharpPrice: '',
      sharpOffPrice: '',

      peakPeriod: '',
      peakPrice: '',
      peakOffPrice: '',

      flatPeriod: '',
      flatPrice: '',
      flatOffPrice: '',

      valleyPeriod: '',
      valleyPrice: '',
      valleyOffPrice: ''
    }
  );
}

function toStr (arr = []) {
  const res = [];
  arr.forEach(item => {
    const s = item.join('-');
    res.push(s);
  });
  return res.join(',');
}

function formartArrayTomap (mounth, arr) {
  const resule = arr.reduce(
    (total, current, index) => {
      const num = current.num;
      if (current[`type-${num}`] === '1') {
        total.sharpPrice = current[`price-${num}`];
        const time = `${current[`time-start-${num}`]}-${current[`time-end-${num}`]},`;
        total.sharpPeriod += time;
      }

      if (current[`type-${num}`] === '2') {
        total.peakPrice = current[`price-${num}`];
        const time = `${current[`time-start-${num}`]}-${current[`time-end-${num}`]},`;
        total.peakPeriod += time;
      }

      if (current[`type-${num}`] === '3') {
        total.flatPrice = current[`price-${num}`];
        const time = `${current[`time-start-${num}`]}-${current[`time-end-${num}`]},`;
        total.flatPeriod += time;
      }

      if (current[`type-${num}`] === '4') {
        total.valleyPrice = current[`price-${num}`];
        const time = `${current[`time-start-${num}`]}-${current[`time-end-${num}`]},`;
        total.valleyPeriod += time;
      }

      if (arr.length === index + 1) {
        total.sharpPeriod = total.sharpPeriod.replace(/\,$/g, '');
        total.peakPeriod = total.peakPeriod.replace(/\,$/g, '');
        total.flatPeriod = total.flatPeriod.replace(/\,$/g, '');
        total.valleyPeriod = total.valleyPeriod.replace(/\,$/g, '');
      }

      return total;
    },
    {
      months: mounth.join(','),
      sharpPeriod: '',
      sharpPrice: '',
      sharpOffPrice: '',

      peakPeriod: '',
      peakPrice: '',
      peakOffPrice: '',

      flatPeriod: '',
      flatPrice: '',
      flatOffPrice: '',

      valleyPeriod: '',
      valleyPrice: '',
      valleyOffPrice: ''
    }
  );

  return resule;
}

//深度克隆
export function deepClone (obj) {
  let copy = null;
  switch (typeof obj) {
    case 'undefined':
      break;
    case 'number':
      copy = obj - 0;
      break;
    case 'string':
      copy = obj + '';
      break;
    case 'boolean':
      copy = obj;
      break;
    case 'object':
      if (obj === null) {
        copy = null;
      } else {
        if (isClass(obj) === 'Array') {
          copy = [];
          for (var i = 0; i < obj.length; i++) {
            copy.push(deepClone(obj[i]));
          }
        } else {
          copy = {};
          for (let j in obj) {
            copy[j] = deepClone(obj[j]);
          }
        }
      }
      break;
    default:
      copy = obj;
      break;
  }
  return copy;
}

export function resulrSegmentskForm (formSegmentsArr = [], schemes = []) {
  const newSchemes = [];

  formSegmentsArr.forEach((table, index) => {
    // 初步还原数据
    const schemesObj = {
      segments: []
    };
    schemesObj.months = table.months;
    schemesObj.schemeName = table.title;
    schemesObj.schemeId = schemes[index].schemeId;
    delete table.title;
    delete table.months;
    const formKeyArr = Object.keys(table);
    const lengthTable = formKeyArr.length / 3;  // 每个表格的条数操作框个数 结束时间、开始时间、功率所以除以3；

    for (let i = 1; i <= lengthTable; i++) {
      // 加num字段
      const segmentsObj = {};
      const num = i < 10 ? `0${i}` : `${i}`;
      segmentsObj.num = num;
      formKeyArr.forEach(item => {
        const index = item.indexOf(num);
        if (index !== -1) {
          segmentsObj[item] = table[item];
        }
      });
      schemesObj.segments.push(segmentsObj);
    }
    newSchemes.push(schemesObj);
  });
  return newSchemes;
}

export function checSegmentskForm (formSegmentsArr = [], schemes = []) {
  const newSchemes = [];
  const errArr = [];
  formSegmentsArr.forEach((table, index) => {
    // 初步还原数据
    const schemesObj = {
      segments: []
    };
    schemesObj.months = schemes[index].months;
    schemesObj.schemeName = table.title;
    schemesObj.schemeId = schemes[index].schemeId;
    delete table.title;
    delete table.months;
    const formKeyArr = Object.keys(table);
    const lengthTable = formKeyArr.length / 3; // 每个表格的条数操作框个数 结束时间、开始时间、功率所以除以3；

    for (let i = 1; i <= lengthTable; i++) {
      // 加num字段
      const segmentsObj = {};
      const num = i < 10 ? `0${i}` : `${i}`;
      segmentsObj.num = num;
      formKeyArr.forEach(item => {
        const index = item.indexOf(num);
        if (index !== -1) {
          segmentsObj[item] = table[item];
        }
      });
      schemesObj.segments.push(segmentsObj);
    }
    newSchemes.push(schemesObj);
  });



  const totalMounth = [];

  newSchemes.forEach(item => {
    // form 检测
    const mounth = item.months.split(',');
    const segments = item.segments || [];
    if (item.months === '') {

      errArr.push(`${item.schemeName}未选择月份`);
    }
    const totalTime = segments.reduce((total, current) => {
      // 所有时间;
      const timeEnd = current[`time-end-${current.num}`];
      const timeStart = current[`time-start-${current.num}`];
      const hours = moment(timeEnd, 'HH:mm').diff(moment(timeStart, 'HH:mm'), 'hours');
      if (timeEnd === timeStart) {
        errArr.push(`${item.schemeName}时段的开始时间和截止时间不能相同`);
      }
      if (hours < 0) {
        errArr.push(`${item.schemeName}时段的截止时间不能小于开始时间`);
      }
      return total + hours;
    }, 0);
    segments.forEach((key, index) => {
      if (index !== 0) {
        const lastItem = segments[index - 1];
        const lastSocEnd = lastItem[`soc-end-${lastItem.num}`];
        const CurrentSocStart = key[`soc-start-${key.num}`];
        if (window.parseInt(CurrentSocStart) < window.parseInt(lastSocEnd))
          return errArr.push(`${item.schemeName}soc有重复，请重新输入`);
      }
    });
    if (totalTime !== 24) {
      errArr.push(`${item.schemeName}时间未覆盖24小时，请重新确认时间`);
    }
    mounth.forEach(item => {
      if (item !== '') totalMounth.push(item);
    });
  });
  if (totalMounth.length < 12) {
    errArr.push('充放电周期未覆盖12个月份');
  } else if (totalMounth.length > 12) {
    errArr.push('充放电周期执行月份有重复');
  }

  const resultFormTable = newSchemes.reduce((total, current) => {
    // 数据检测完成, 深度还原数据;
    const resObj = {
      ...current,
      segments: []
    };
    current.segments.forEach(item => {
      const obj = {};
      const num = item.num;
      obj.endTime = item[`time-end-${num}`];
      obj.startTime = item[`time-start-${num}`];
      obj.lowerSoc = item[`soc-start-${num}`];
      obj.upperSoc = item[`soc-end-${num}`];
      obj.power = item[`power-${num}`];
      if (
        (obj.endTime !== '00:00' && obj.startTime !== '00:00') ||
        (obj.endTime !== '00:00' || obj.startTime !== '00:00')
      ) {
        resObj.segments.push(obj);
      }
    });
    total.push(resObj);
    return total;
  }, []);

  const R = {
    errArr,
    resultFormTable
  };
  return R;
}

export function getSchemesGroup (values, length) {
  const arr = []; // 依据index分组;
  for (let i = 0; i < length; i++) {
    const obj = {};
    const index = String(i);
    obj.title = SCHEMES[i + 1];
    Object.keys(values).forEach(key => {
      const keyIndex = key.split('_');
      if (index === keyIndex[0]) {
        obj[keyIndex[1]] = values[key];
      }
    });
    arr.push(obj);
  }
  return arr;
}

export function formatTableData (segments, capacity) {
  const arr = [];
  segments.forEach((item, index) => {
    const obj = {};
    const len = index + 1;
    // const power =
    //   (capacity * ((window.parseInt(item.upperSoc) - window.parseInt(item.lowerSoc)) / 100)) /
    //   (window.parseInt(item.endTime) - window.parseInt(item.startTime));
    obj.num = len < 10 ? `0${len}` : `${len}`;
    obj.time = item.time ? item.time : [item.startTime, item.endTime];
    // obj.soc = [item.lowerSoc, item.upperSoc];
    // obj.power = item.power?(isNaN(item.power) ? '' : item.power.toFixed(2)):'';
    obj.power = item.power
    obj.capacity = capacity;
    arr.push(obj);
  });
  return arr;
}

export function formatTableDataReversal (arr) {
  const result = [];
  arr.forEach(item => {
    const obj = {};
    const soc = item.soc || [];
    const time = item.time || [];
    obj.startTime = time[0];
    obj.endTime = time[1];
    // obj.lowerSoc = soc[0];
    // obj.upperSoc = soc[1];
    if (item.power) {
      obj.power = item.power
    } else {
      obj.power = 0
    }
    result.push(obj);
  });
  return result;
}

export function formantSchemesItem (arr) {
  const result = [];
  arr.forEach(item => {
    const keyArr = Object.keys(item)[1].split('-');
    const num = keyArr[keyArr.length - 1];
    const obj = {
      startTime: item[`time-start-${num}`],
      endTime: item[`time-end-${num}`],
      lowerSoc: item[`soc-start-${num}`],
      upperSoc: item[`soc-end-${num}`]
    };
    result.push(obj);
  });
  return result;
}

export function formantSchemesData (arr = []) {
  const timeLength = arr.length;
  const list = arr.reduce(
    (total, current) => {
      if (current.type === 1) {
        if (current.time) total[0].time.push(current.time);
        total[0].price = current.price;
        total[0].dischargePrice = current.dischargePrice;
      }
      if (current.type === 2) {
        if (current.time) total[1].time.push(current.time);
        total[1].price = current.price;
        total[1].dischargePrice = current.dischargePrice;
      }
      if (current.type === 3) {
        if (current.time) total[2].time.push(current.time);
        total[2].price = current.price;
        total[2].dischargePrice = current.dischargePrice;
      }
      if (current.type === 4) {
        if (current.time) total[3].time.push(current.time);
        total[3].price = current.price;
        total[3].dischargePrice = current.dischargePrice;
      }
      return total;
    },
    [
      {
        type: 1,
        time: [],
        num: '01'
      },
      {
        type: 2,
        time: [],
        num: '02'
      },
      {
        type: 3,
        time: [],
        num: '03'
      },
      {
        type: 4,
        time: [],
        num: '04'
      }
    ]
  );
  return [list, timeLength];
}

export function filterData (data = {}) {
  const seasonPrice = (data.seasonPrice || []).filter(
    item => item.time[0] && item.time[0].length === 2 && item.price
  );
  const unseasonPrice = (data.unseasonPrice || []).filter(
    item => item.time[0] && item.time[0].length === 2 && item.price
  );
  return {
    ...data,
    seasonPrice,
    unseasonPrice
  };
}

function isClass (o) {
  if (o === null) return 'Null';
  if (o === undefined) return 'Undefined';
  return Object.prototype.toString.call(o).slice(8, -1);
}

function getPriceByNum (num, price) {
  const arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(price);
  }
  return arr;
}
function uniq (array) {
  const temp = [];
  for (let i = 0; i < array.length; i++) {
    if (temp.indexOf(array[i]) === -1) {
      temp.push(array[i]);
    }
  }
  return temp;
}

export function uniqArr (arr, key) {
  const hash = [];
  const result = [];
  arr.forEach(item => {
    if (hash.indexOf(item[key]) === -1) {
      hash.push(item[key]);
      result.push(item);
    }
  });
  return result;
}

export function sort (arr) {
  for (let j = 0; j < arr.length - 1; j++) {
    for (let i = 0; i < arr.length - 1 - j; i++) {
      if (Number(arr[i]) > Number(arr[i + 1])) {
        const temp = arr[i];
        arr[i] = arr[i + 1];
        arr[i + 1] = temp;
      }
    }
  }
  return arr;
}


function tips (arr = []) {
  let tip = '';
  // if (arr.indexOf('1') === -1) {
  //   tip += '尖、';
  // }
  if (arr.indexOf('2') === -1) {
    tip += '峰、';
  }
  if (arr.indexOf('3') === -1) {
    tip += '平、';
  }
  if (arr.indexOf('4') === -1) {
    tip += '谷、';
  }
  return tip;
}

// 在集团路由下通过集团 ID 缓存重置 enterpriseId 字段
export function handleResetEnterpriseId (data) {

  if (Object.values(GROUP_ROUTE_PATHS).some(path => {
    let finalPath = path;
    if (path.includes('/:stationId')) {
      finalPath = path.replace('/:stationId', '')
    }
    return window.location.hash.includes(`#${finalPath}`)
  })) {
    const idCache = localStorage.getItem('enterpriseIdCache');
    if (data?.enterpriseId && idCache && data.enterpriseId !== idCache) {
      data.enterpriseId = idCache;
    }
  }
  return data;
}

/**
 * 获取相同单位的电量数值
 */
export function getSameUnitElectricity (...values) {
  const min = Math.min(...values)
  const formattedValue = formatElectricity(min)
  switch (formattedValue.unit) {
    case 'kWh':
      return values.map(value => formatElectricity(value, 'k'))
    case 'MWh':
      return values.map(value => formatElectricity(value, 'm'))
    case 'GWh':
      return values.map(value => formatElectricity(value, 'g'))
  }
}


export function getSameUnitPower (...values) {
  const min = Math.min(...values)
  const formattedValue = formatCapacity(min)
  switch (formattedValue.unit) {
    case 'kW':
      return values.map(value => formatCapacity(value, 'k'))
    case 'MW':
      return values.map(value => formatCapacity(value, 'm'))
    case 'GW':
      return values.map(value => formatCapacity(value, 'g'))
  }
}



// 设置缓存
export function setLocationParams () {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match;
  const params = {};

  while ((match = regex.exec(window.location.href)) !== null) {
    params[match[1]] = decodeURIComponent(match[2]); // 解码参数值
  }
  const emsStationId = params.emsStationId;
  const customerId = params.customerId;
  const enterpriseIdCache = params.enterpriseIdCache;
  const emsCustomerId = params.emsCustomerId;
  const stationIds = params.stationIds;

  const feeMeasurementPointId = params.feeMeasurementPointId;
  const ownerId = params.ownerId;

  const storedEnergyStationId = params.storedEnergyStationId;

  if (stationIds) {
    localStorage.setItem('stationIds', stationIds)
  }
  if (feeMeasurementPointId) {
    localStorage.setItem('ext', JSON.stringify({ feeMeasurementPointId, ownerId }))
  }

  if (storedEnergyStationId) {
    localStorage.setItem('stationInfo', JSON.stringify({ storedEnergyStationId, stationId: stationIds }))
  }

  if (emsStationId) {
    localStorage.setItem('emsStationId', emsStationId)
  }
  if (customerId) {
    localStorage.setItem('customerId', customerId)
    if (!emsStationId) {
      localStorage.setItem('emsStationId', customerId)
    }


  }
  if (enterpriseIdCache) {
    localStorage.setItem('enterpriseIdCache', enterpriseIdCache)
  }
  if (emsCustomerId) {
    localStorage.setItem('emsCustomerId', emsCustomerId)
  }

}


//获取地址栏参数
export function getUrlParams () {
  const queryParams = {};
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const url = window.location.href;
  let match;
  while ((match = regex.exec(url))) {
    queryParams[match[1]] = decodeURIComponent(match[2]);
  }
  return queryParams;
}

export function generateUUID () {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

// 大屏的token需要写死，否则将很快过期
export function isScreen () {
  // 线上环境写死
  // 12/31替换新token
  const defaultToken = localStorage.getItem("screenToken") || ''
  // 获取当前浏览器地址
  const currentURL = window.location.href;

  // if (currentURL.indexOf('test-ems.yuanli-inc.com') > -1) return storage.get('token')
  // if (currentURL.indexOf('dev-ems.yuanli-inc.com') > -1) return storage.get('token')
  // // 本地环境
  // if (currentURL.indexOf('localhost') > -1) return storage.get('token')
  // 检查当前地址是否包含 "/screen-property"
  if (currentURL.indexOf("/screen") !== -1 || currentURL.indexOf("/hall") !== -1) {
    if (defaultToken) {
      return defaultToken
    } else {
      return storage.get('token') || ''
    }

  }
  return storage.get('token') || ''
}
