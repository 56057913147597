

let curModel = process.env.UMI_ENV;


// 默认开发环境
// http://221.224.90.218:8099
// let target = 'http://221.224.90.218:8099'//http://221.224.90.218:8099
// let target = 'https://dev-ems.yuanli-inc.com/';

let target = 'http://221.224.90.218:8099/'
let eWork = 'http://221.224.90.218:8099/'
// https://dev-ems.yuanli-inc.com

// 测试环境
if (curModel === 'test') {
  target = 'http://192.168.1.12:15301/'
  eWork = 'https://test-ems.yuanli-inc.com/'
}

// 生产环境
else if (curModel === 'prod') {
  // target = 'https://ems.daosenergy.com/'
  target = 'http://192.168.0.30:15301/' // 本地联调
  eWork = 'https://ems.daosenergy.com/'
}

let commonProxy = {
  target,
  secure: false,
  changeOrigin: true,
  pathRewrite: { '^/': '' },
}

let eProxy = {
  target: eWork,
  secure: false,
  changeOrigin: true,
  pathRewrite: { '^/': '' },
}

export default {
  '/api': commonProxy,
  '/eai': eProxy,
  '/getSVG': commonProxy,
  '/fix-browser.js': commonProxy,
  '/redirect.js': commonProxy,
  '/joint/images': {
    target: "http://graph.test.smarteoc.com/",
    changeOrigin: true,
    pathRewrite: { '^/': '' },
  },
};