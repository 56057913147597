import request from '@/utils/request';
import { identity } from 'lodash';
import * as store from './storage';
import { message } from '@ui';
import { handleResetEnterpriseId, isScreen } from '@/utils/toolFunction';
let lastTime = null;
import { getUrlParams } from '@/utils/toolFunction';
const { token } = getUrlParams()
function ErrorRes (code, message = '') {
  this.requestId = null;
  this.type = 'ClientError';
  this.code = code;
  this.message = message;
}

export const requestData = async (method, datas) => {

  try {
    const body = JSON.stringify(handleResetEnterpriseId(datas));

    const tokenParams = isScreen() ? {
      Authorization: `Bearer ${isScreen()}`
    } : {}

    const data = await request(method, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        ...tokenParams
      },
      body
    });
    lastTime = moment();
    return data;
  } catch (ex) {
    console.log(ex);
    return new ErrorRes('fetch error');
  }
};


export async function getData (service, action, params = {}, version = 'latest') {

  const { saasStationId, isHall } = getUrlParams()
  // 大屏需要加上配电id
  const staionInfo = saasStationId && isHall ? {
    loginIdentity: { saasStationId: saasStationId }
  } : {
    platformType: params.platformType || 'dayou'
  }
  const resData = await requestData(`${params.test && process.env.NODE_ENV === 'development' ? 'http://localhost:3080' : ''}/api/${version}/${service}${action ? `/${action}` : ''}`, {
    ...params,
    ...staionInfo
  });
  if (resData.err) {
    // message.error('请求超时!');
    return false;
  } else {
    return resData;
  }
}

export function getBatchData (tagName, reqList = []) {
  return getData('batchQuery', `node_${tagName}`, {
    paramList: reqList.map(([service, action, param = {}]) => ({ service, action, ...param }))
  });
}

export function getDataParti (service, action, transfomer = identity, ...args) {
  return async function _getDataParti (...params) {
    return await getData(service, action, transfomer(...params), ...args);
  };
}

export function getExpectedData (type) {
  let expected = null;
  switch (type) {
    case 'boolean':
      expected = false;
      break;
    case 'string':
      expected = '';
      break;
    case 'object':
      expected = {};
      break;
    case 'array':
    case 'page':
      expected = [];
      break;
    case 'number':
      expected = 0;
      break;
    default:
      expected = null;
  }

  const typesList = ['array', 'page'];

  return (service, action, transfomerArgs = identity, transfomerExpected = identity) => async args => {
    let e = _.clone(expected);
    const argsTransformed = transfomerArgs(args);
    const res = await getData(service, action, argsTransformed);

    if (res.code === null || res.code === '200') {
      e = type === 'boolean' ? true : (typesList.some(i => type === i) ? res.dataList : res.data) ?? e;
    }
    e = transfomerExpected(e, res, args, argsTransformed);

    return [e, res];
  };
}

export const getDataBoolean = getExpectedData('boolean');

export const getDataString = getExpectedData('string');

export const getDataObject = getExpectedData('object');

export const getDataArray = getExpectedData('array');

export const getDataPage = getExpectedData('page');

export const getDataNumber = getExpectedData('number');

setInterval(async () => {
  const token = store.get('token');
  if (token && token.length > 0) {
    if (lastTime && lastTime.add(5, 'minutes').isAfter(moment())) {
      const res = await getData('user', 'heartBeat', {});
      if (res.code) console.log('heartBeat error', res);
    }
  }
}, 5 * 60 * 1000);
